<template>
    <!-- 新建banner页面 -->
    <div class="positionT0L0">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
            <a-form-model-item label="推送渠道" prop="channel" :wrapper-col="{ span: 16 }">
                <a-radio-group name="radioGroup" v-model="form.channel" :disabled="isDisabled">
                    <a-radio :value="1">APP私信</a-radio>
                    <a-radio :value="2">APP私信+PUSH</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="消息推送名称" prop="title">
                <a-input v-model="form.title" placeholder="请输入消息推送名称" :maxLength="32" :disabled="isDisabled">
                    <div slot="addonAfter">{{ form.title.length }}/32</div>
                </a-input>
            </a-form-model-item>
            <a-form-model-item label="消息推送内容" prop="content">
                <a-textarea v-model="form.content" placeholder="请输入消息推送内容" :rows="4" :disabled="isDisabled"/>
            </a-form-model-item>

            <a-form-model-item label="消息推送详情" prop="targetType" :wrapper-col="{ span: 16 }">
                <a-radio-group name="radioGroup" :value="form.targetType" @change="targetTypeChange" :disabled="isDisabled">
                    <a-radio :value="1">商品</a-radio>
                    <a-radio :value="2">跳转链接</a-radio>
                    <a-radio :value="3">自定义内容</a-radio>
                </a-radio-group>
                <div v-if="form.targetType == 1">
                    <div class="product-box" v-if="isShowGoods">
                        <a-icon type="close-circle" v-if="$route.params.id == 0" :style="{ fontSize: '20px', color: '#9FA5AF' }" class="product-close" @click="closeHandle"/>
                        <div class="product-wrap flexbox justify middle" :class="{ bg: this.$route.params.id != 0 }">
                            <div class="left-wrap flex1 flexbox left">
                                <div class="product-img">
                                    <img class="p-img" :src="goodsDetail.coverUrl" alt="图片"/>
                                </div>
                                <div class="product-info flex1">
                                    <div style="height: 56px">
                                        <div class="p-title more-t">{{ goodsDetail.businessName }}</div>
                                        <div v-show="form.businessType == 1 && goodsDetail.pricingType == 0" class="p-price">免费</div>
                                        <div v-show="form.businessType == 1 && goodsDetail.pricingType == 1" class="p-price">￥{{ goodsDetail.price }}</div>
                                        <div v-show="form.businessType == 1 && goodsDetail.pricingType == 2" class="p-price">不支持单独售卖</div>
                                        <div v-show="form.businessType == 2" class="p-price">￥{{ goodsDetail.price }}</div>
                                    </div>
                                    <div class="p-type">商品类型:{{ form.businessType == "1" ? "课程" : "会员" }}</div>
                                </div>
                            </div>
                            <div class="right-wrap" v-if="$route.params.id == 0">
                                <a-button type="primary" @click="editGoods">更换商品</a-button>
                            </div>
                        </div>
                    </div>
                    <a-button v-else type="primary" size="large" @click="addClick('1')">添加商品</a-button>
                </div>
                <div v-if="form.targetType == 2">
                    <a-input placeholder="请输入跳转链接" v-model="form.targetText" style="width: 500px" :disabled="isDisabled"/>
                </div>
                <Ueditor v-if="form.targetType == 3" :content="form.targetText" @editorFn="editorFn"/>
                <p v-if="isErrorSize" style="color: red">{{ form.targetType == 1 ? '请添加商品' : form.targetType == 2 ? '请输入跳转链接' : '请输入自定义内容' }}</p>
            </a-form-model-item>
            <a-form-model-item ref="userSelection" label="用户筛选" prop="userSelection">
                <a-radio-group name="radioGroup" :value="form.userSelection" @change="userChange" :disabled="isDisabled">
                    <a-radio :value="1">全部用户</a-radio>
                    <a-radio :value="2">课程筛选</a-radio>
                    <a-radio :value="3">定向用户</a-radio>
                </a-radio-group>
                <br>
                <template v-if="form.userSelection == 2">
                    <a-button v-if="$route.params.id == 0" type="primary" size="large" @click="addClick('2')">添加商品</a-button>
                    <div class="boxCurriculum">
                        <div class="curriculum" v-if="selectedRowsCourseArr.length >0">
                            <p>课程</p>
                            <ul>
                                <li v-for="(item,index) in selectedRowsCourseArr" :key="item.courseNo">
                                    <img :src="item.coverUrl" class="img" alt="">
                                        <div class="content">
                                            <span class="name">{{ item.name }}</span>
                                            <span v-show="item.pricingType == 0" class="p-price">免费</span>
                                            <span v-show="item.pricingType == 1" class="p-price">￥{{ item.price }}</span>
                                            <span v-show="item.pricingType == 2" class="p-price">不支持单独售卖</span>
                                            <span class="kcName">商品类型：课程</span>
                                        </div>
                                    <a-icon v-if="$route.params.id == 0" type="close" :style="{ fontSize: '20px', color: '#9FA5AF', marginTop: '30px' }" class="product-close" @click="closeCourseClick(item,index)"/>
                                </li>
                            </ul>
                        </div>
                        <div class="vip" v-if="selectedRowsMemberArr.length >0">
                            <p>会员</p>
                            <ul>
                                <li v-for="(item,index) in selectedRowsMemberArr" :key="item.memberNo">
                                    <img :src="item.coverImg" class="img" alt="">
                                    <span class="content">
                                    <span class="name">{{ item.memberName }}</span>
                                    <span class="je">¥{{ item.price }}</span>
                                    <span class="kcName">课程类型：会员</span>
                                </span>
                                    <a-icon v-if="$route.params.id == 0" type="close" :style="{ fontSize: '20px', color: '#9FA5AF', marginTop: '30px' }" class="product-close" @click="closeMemberClick(item,index)"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
                <a-textarea v-else-if="form.userSelection == 3" v-model="form.phones" placeholder="请输入用户手机号" :rows="4" :disabled="isDisabled"/>
                <p v-if="isErrorSize1" style="color: red">{{ form.userSelection == 2 ? '请添加商品' : form.userSelection == 3 ? '请输入手机号' : '' }}</p>
            </a-form-model-item>
            <a-form-model-item ref="datePickerArr" label="有效期" prop="datePickerArr" :wrapper-col="{ span: 16 }">
                <a-range-picker @change="onChangeDate" v-model="form.datePickerArr" :disabled-date="disabledDate" :disabled="isDisabled"/>
            </a-form-model-item>
            
            <a-form-model-item ref="pushTimeVal" label="发送时间" prop="pushTimeVal" :wrapper-col="{ span: 16 }">
                <a-date-picker v-model="form.pushTimeVal" :disabled-date="disabledDate" :disabled-time="disabledDateTime" format="YYYY-MM-DD HH:mm:ss" showTime @change="pushTimeChange" :disabled="isDisabled"/>
            </a-form-model-item>
        </a-form-model>
        
        <FooterToolBar :collapsed="sideCollapsed">
            <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
            <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading" v-if="$route.params.id == 0">保存</a-button>
        </FooterToolBar>
        
        <RadioModal
            v-if="modalVisible"
            :modalVisible="modalVisible"
            :type="0"
            :tabType="form.businessType"
            :selectedRowKeys="selectedRowKeys"
            :isShowMember="false"
            @cancel="cancel"
            @ok="ok"
        />
    
        <NewCheckModal
            v-if="modalVisibleCheck"
            :modalVisible="modalVisibleCheck"
            :type="0"
            :tabType="form.businessType"
            :selectedRowKeys="selectedRowKeysCheck"
            :isShowMember="true"
            :coursesArr="form.businessNoAry"
            :memberArr="form.memberNoAry"
            :selectedRowsCourseArr="selectedRowsCourseArr"
            :selectedRowsMemberArr="selectedRowsMemberArr"
            @cancel="cancelCheck"
            @ok="okCheck"
        />
    </div>
</template>

<script>
import moment from "moment";
import {baseMixin} from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import {message} from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import CropperUpload from "@/components/file/CropperUpload";
import Ueditor from "@/components/ueditor/ueditor";
import RadioModal from "@/components/courseMember/RadioModal";
import NewCheckModal from "@/components/courseMember/newCheckModal";
import {AddNoticeMessage, EditNoticeMessage, GetNoticeMessage} from "@/request/api/noticeManage";
export default {
    mixins: [baseMixin],
    components: {CropperUpload, FooterToolBar, Ueditor, RadioModal, NewCheckModal},
    created() {
        let id = this.$route.params.id;
        id != 0 ? this.isDisabled = true : false
        if (id !== "0") {
            this.isShowGoods = true;
            GetNoticeMessage({id: id}).then(({code, data}) => {
                if (code == 200) {
                    this.form = data
                    this.form.id = this.$route.params.id
                    this.goodsDetail.coverUrl = data.targetInfo.coverUrl
                    this.goodsDetail.businessName = data.targetInfo.name
                    this.goodsDetail.price = data.targetInfo.price
                    this.goodsDetail.pricingType = data.targetInfo.putawayFlag
                    let startTime = data.startTime.substring(0,10)
                    let endTime = data.endTime.substring(0,10)
                    this.form.datePickerArr = [startTime,endTime]
                    this.form.startTime = startTime
                    this.form.endTime = endTime
                    this.selectedRowsCourseArr = data.businessAry
                    this.selectedRowsMemberArr = data.memberAry
                    this.form.pushTimeVal = data.pushTime
                    this.form.pushTime = data.pushTime
                    this.form.phones = data.phoneAry && data.phoneAry.length>0 ? data.phoneAry.toString() : ''
                }
            });
        }
    },
    data() {
        return {
            locale,
            isErrorSize:false,
            isErrorSize1:false,
            isDisabled:false,
            goodsDetail: {coverUrl: "", businessName: "", price: "", pricingType:'',}, // 商品详情
            isShowGoods: false, // 商品详情是否显示
            modalVisible: false, // 单选模态框是否显示隐藏
            modalVisibleCheck:false, // 多选模态框是否显示隐藏
            keepLoading: false, // 保存的按钮是否加载
            form: {
                id: this.$route.params.id == 0 ? '' : this.$route.params.id,
                title: "", //消息标题
                content:'', //消息内容
                channel:1, //推送渠道 1APP私信 2私信+push
                targetType: 1, //消息推送类型 1 课程 2 h5链接 3 富文本 4 会员开通页面 5小程序 6跳转抖音 7首页读书会员 8我的优惠券列表
                targetText: "", //消息推送详情
                userSelection:1, //用户筛选1全部用户 2课程筛选 3指定用户
                datePickerArr:[], //有效期
                startTime:'', //开始时间
                endTime:'', //结束日期
                pushTimeVal:'', //推送时间
                pushTime:'', //推送时间
                businessType: 1, //课程类型选择
                // 课程/会员
                businessNoAry:[],
                memberNoAry:[],
                phoneAry:[], //手机号集合
                phones:'', //手机号集合
                text:'',
            },
            rules: {
                channel: [
                    {required: true, message: "推送渠道", trigger: "change"},
                ],
                title: [
                    {required: true, message: "输入的内容不能为空", trigger: "blur"},
                    {max: 32, message: "长度最长为32个", trigger: "blur"},
                ],
                content: [
                    {required: true, message: "消息内容", trigger: "blur"},
                ],
                targetType: [
                    {required: true, message: "消息推送类型", trigger: "change"},
                ],
                userSelection: [
                    {required: true, message: " 请选择用户筛选", trigger: "change"},
                ],
                datePickerArr: [
                    {required: true, message: " 请选择有效期", trigger: "change"},
                ],
                pushTimeVal: [
                    {required: true, message: " 请选择推送时间", trigger: "change"},
                ],
            },
            disabled: false,
            statusVal:'', // 点击商品介绍的判断
            selectedRowKeys: [], // 单选选中key值
            selectedRowKeysCheck:[], // 多选选中key值
            selectedRowsCourseArr:[], // 多选选中课程的集合
            selectedRowsMemberArr:[], // 多选选中会员课程的集合
        };
    },
    mounted() {
    },
    methods: {
        // 限制日期
        disabledDate(current) {
            return current && current < moment().subtract(1, 'days')
        },
        disabledDateTime(){
            return {
                disabledHours: () => [0,1,2,3,4,5,6,24],
                disabledMinutes: () => this.range(),
                disabledSeconds: () => [],
            }
        },
        range(start, end){
            let result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        // 有效期
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.form.startTime = dateString[0] + ' 00:00:00';
                this.form.endTime = dateString[1] + ' 23:59:59';
            } else {
                this.form.startTime = "";
                this.form.endTime = "";
            }
        },
        
        // 发送时间
        pushTimeChange(date, dateString){
            if (dateString != "") {
                this.form.pushTime = dateString;
            } else {
                this.form.pushTime = "";
            }
        },
        
        // 消息推送详情
        targetTypeChange(e) {
            this.form.targetText = ''
            if (e.target.value == 1) {
                this.isShowGoods = false;
            }
            this.form.targetType = e.target.value;
        },
        //用户筛选
        userChange(e){
            this.form.userSelection = e.target.value;
            this.form.businessNoAry = []
            this.form.memberNoAry = []
            this.selectedRowsMemberArr = []
            this.selectedRowsCourseArr = []
            this.form.phoneAry = []
            this.form.phones = ''
            this.isErrorSize1 = false
        },
        // 富文本的函数
        editorFn(value) {
            this.form.targetText = value;
        },
        // 添加商品的按钮
        addClick(val) {
            this.statusVal = val
            if(val == '2'){
                this.modalVisibleCheck = true;
            }else{
                this.modalVisible = true;
            }
        },
        // 更换商品的按钮
        editGoods() {
            this.modalVisible = true;
        },
        // 商品关闭按钮
        closeHandle() {
            this.goodsDetail = null;
            this.isShowGoods = false;
            this.form.targetText = ''
        },
        
        // 单选 --- 模态框的取消按钮
        cancel() {
            this.modalVisible = false;
        },
        // 单选 --- 模态框的确定按钮
        ok(SelectedRowKeys, obj, businessType) {
            this.form.businessType = Number(businessType);
            this.form.targetText = SelectedRowKeys[0]
            console.log(this.form.targetText)
            if (obj.memberNo || obj.key) {
                if (obj.memberNo) {
                    this.form.businessNo = obj.memberNo;
                    this.$set(this.goodsDetail, "businessName", obj.memberName);
                    this.$set(this.goodsDetail, "coverUrl", obj.coverImg);
                    this.$set(this.goodsDetail, "price", obj.price);
                } else {
                    this.form.businessNo = obj.key;
                    this.goodsDetail = obj;
                    this.$set(this.goodsDetail, "businessName", obj.name);
                    this.$set(this.goodsDetail, "coverUrl", obj.coverUrl);
                    this.$set(this.goodsDetail, "price", obj.price);
                }
                this.modalVisible = false;
                this.isShowGoods = true;
            } else {
                this.modalVisible = false;
            }
        },
        
        // 多选 --- 模态框的取消按钮
        cancelCheck() {
            this.modalVisibleCheck = false;
        },
        // 多选 --- 模态框的确定按钮
        okCheck(courseSelectedRowKeys, memberSelectedRowKeys, selectedRowsCourse, selectedRowsMember) {
            this.modalVisibleCheck = false
            this.form.businessNoAry = courseSelectedRowKeys
            this.form.memberNoAry = memberSelectedRowKeys
            this.selectedRowsCourseArr = selectedRowsCourse.length>0 ? selectedRowsCourse : []
            this.selectedRowsMemberArr = selectedRowsMember.length>0 ? selectedRowsMember : []
        },
    
        // 点击多选❌
        closeCourseClick(item,index){
            this.selectedRowsCourseArr.splice(index,1)
            this.form.businessNoAry.splice(index,1)
        },
    
        closeMemberClick(item,index){
            this.selectedRowsMemberArr.splice(index,1)
            this.form.memberNoAry.splice(index,1)
        },
        
        // 保存的按钮
        keepClick() {
            this.keepLoading = true;
            setTimeout(() => {
                this.keepLoading = false;
            }, 3000);
            
            if(!this.form.targetText){
                this.isErrorSize = true
                return false
            }else{
                this.isErrorSize = false
            }
            
            if(this.form.userSelection == 2){
                if(this.form.businessNoAry.length == 0 && this.form.memberNoAry.length == 0){
                    this.isErrorSize1 = true
                    return false
                }else{
                    this.isErrorSize1 = false
                }
            }else if(this.form.userSelection == 3){
                if(!this.form.phones){
                    this.isErrorSize1 = true
                    return false
                }else{
                    this.isErrorSize1 = false
                }
            }
            
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.form.phoneAry = this.form.phones ? this.form.phones.split(',') : []
                    AddNoticeMessage(this.form).then(({code}) => {
                        if (code == 200) {
                            message.success("新增成功");
                            location.href = "/noticeManage/noticeIndex";
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.form_span {
    top: -4px;
}

.boxCurriculum{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .curriculum{
        margin-bottom: 20px;
        p{
            font-size: 20px;
        }
        ul{
            border: 1px solid #ccc;
            border-bottom: none;
            li{
                border-bottom: 1px solid #ccc;
                border-radius: 4px;
                display: flex;
                line-height: 1.5;
                padding-right: 10px;
                .img{
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    margin-right: 10px;
                }
                .content{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .name{
                        font-size: 14px;
                        color: #000;
                        margin-top: 6px;
                    }
                    .je{
                        flex: 1;
                        color: red;
                    }
                    .kcName{
                        color: #9fa5af;
                        font-size: 12px;
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .vip{
        p{
            font-size: 20px;
        }
        ul{
            border: 1px solid #ccc;
            border-bottom: none;
            li{
                border-bottom: 1px solid #ccc;
                border-radius: 4px;
                display: flex;
                line-height: 1.5;
                padding-right: 10px;
                .img{
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    margin-right: 10px;
                }
                .content{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .name{
                        font-size: 14px;
                        color: #000;
                        margin-top: 6px;
                    }
                    .je{
                        flex: 1;
                        color: red;
                    }
                    .kcName{
                        color: #9fa5af;
                        font-size: 12px;
                        line-height: 32px;
                    }
                }
            }
        }
    }
}

/deep/ .ant-radio-group {
    margin-bottom: 20px;
}

.product-box {
    position: relative;
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 4px;
    
    .product-close {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
    }
    
    .product-wrap {
        height: 86px;
        
        .left-wrap {
            .product-img {
                margin-right: 10px;
                height: 86px;
                
                .p-img {
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            
            .product-info {
                flex-direction: column;
                height: 86px;
                line-height: 1.5;
                .p-title {
                    margin-top: 6px;
                    font-size: 14px;
                    color: #000;
                }
                
                .p-price {
                    color: red;
                }
                
                .p-type {
                    color: #9fa5af;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        
        .right-wrap {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
.p-price {
    color: red;
}
/deep/ .edu-type .ant-form-item-children {
    display: flex;
}

/deep/ .edu-type .ant-select:nth-child(2) {
    margin-left: 20px;
}

/deep/ .edu-quantity .ant-input-number {
    width: 100%;
}

/deep/ .date-picker .ant-form-item-children {
    display: flex;
    align-items: center;
}
</style>
