<template>
    <a-modal title="添加商品" width="64%" :visible="modalVisible" okText="确定" cancelText="取消" @ok="ok" @cancel="() => {$emit('cancel');}">
        <a-tabs v-model="businessType" @change="tabChange">
            <a-tab-pane key="1" tab="课程">
                <div class="search-box">
                    <a-form-model layout="inline" :model="searchForm" @submit="handleSubmit" @submit.native.prevent>
                        <a-form-model-item label="课程状态">
                            <a-select v-model="searchForm.putawayFlag" style="width: 120px" @change="changePutawayFlag">
                                <a-select-option v-for="item in putawayFlagArr" :key="item.key">{{ item.value }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-input v-model="searchForm.name" placeholder="请输入课程名称">
                                <a-icon slot="prefix" type="search" style="color: rgba(0, 0, 0, 0.25)"/>
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-button class="margin_right10" icon="search" type="primary" html-type="submit">搜索
                            </a-button>
                            <a-button type="primary" @click="searchForm.name = ''">重置</a-button>
                        </a-form-model-item>
                    </a-form-model>
                </div>
                <a-table ref="courseTable" class="courseTable" :scroll="{ y: 300 }" :pagination="false" :loading="courseLoading" :columns="courseColumns" :data-source="courseList" :row-selection="{selectedRowKeys: courseSelectedRowKeys,onChange: onSelectCourseChange,type: 'checkbox',}">
                    <div class="flexbox middle" slot="nameImgUrl" slot-scope="text, record">
                        <div>
                            <img v-viewer class="course-img" :src="record.coverUrl"/>
                        </div>
                        <label>{{ record.name }}</label>
                    </div>
                    <span slot="putawayFlag" slot-scope="text, record">
                        <a-badge v-if="record.putawayFlag == 0" status="default" text="已下架"/>
                        <a-badge v-if="record.putawayFlag == 1" status="processing" text="定时上架"/>
                        <a-badge v-if="record.putawayFlag == 2" status="success" text="已上架"/>
                        <a-badge v-if="record.putawayFlag == 3" status="warning" text="暂不上架"/>
                    </span>
                    <span slot="courseType" slot-scope="courseType, record">
                        <span v-show="record.courseType == 1">伴读课</span>
                        <span v-show="record.courseType == 2">网课</span>
                    </span>
                    <span slot="price" slot-scope="price, record">
                        <span v-show="record.pricingType == 0">免费</span>
                        <span v-show="record.pricingType == 1">{{ record.price }}</span>
                        <span v-show="record.pricingType == 2">不支持单独售卖</span>
                    </span>
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="2" tab="会员" v-if="isShowMember">
                <div class="search-box">
                    <a-form-model layout="inline" :model="searchForm" @submit="handleSubmit">
                        <a-form-model-item label="会员状态">
                            <a-select v-model="memberStatus" style="width: 120px" @change="changeStatus">
                                <a-select-option v-for="item in statusArr" :key="item.key">{{ item.value }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-input v-model="searchForm.name" placeholder="请输入会员名称">
                                <a-icon slot="prefix" type="search" style="color: rgba(0, 0, 0, 0.25)"/>
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-button class="margin_right10" icon="search" type="primary" html-type="submit">搜索
                            </a-button>
                            <a-button type="primary" @click="searchForm.name = ''">重置</a-button>
                        </a-form-model-item>
                    </a-form-model>
                </div>
                <a-table ref="memberTable" class="memberTable" :scroll="{ y: 300 }" :pagination="false" :loading="memberLoading" :columns="memberColumns" :data-source="memberList" :row-selection="{selectedRowKeys: memberSelectedRowKeys,onChange: onSelectMemberChange,type: 'checkbox',}">
                    <div class="flexbox middle" slot="nameImgUrl" slot-scope="text, record">
                        <div>
                            <img v-viewer class="course-img" :src="record.coverImg"/>
                        </div>
                        <label>{{ record.memberName }}</label>
                    </div>
                    <span slot="forbiddenStatus" slot-scope="text, record">
                        <a-badge v-if="record.forbiddenStatus == 1" status="success" :text="record.forbiddenStatusText"/>
                        <a-badge v-if="record.forbiddenStatus == 2" status="error" :text="record.forbiddenStatusText"/>
                    </span>
                </a-table>
            </a-tab-pane>
        </a-tabs>
    </a-modal>
</template>

<script>
import {CourseListApi, MemberListApi} from "@/request/api/marketingCenter"; // 0 - 课程管理列表
import {TabExistsCourseInfoApi} from "@/request/api/indexManage"; // 1 - 课程Tab-去重
import {baseMixin} from "@/store/app-mixin";
import {GetCourseList} from "@/request/api/physicalCard";

export default {
    mixins: [baseMixin],
    props: {
        modalVisible: {
            type: Boolean,
            required: true,
        },
        type: {
            // 请求接口类型: 0 - 课程管理列表，1 - 课程Tab-去重
            type: Number,
            default: 0,
        },
        tabType: {
            // 1-课程，2-会员
            type: Number,
            default: 1,
        },
        isShowMember: {
            // 是否显示会员tab
            type: Boolean,
            default: false,
        },
        selectedRowKeys: {
            // 已选择的 keys
            type: Array,
            default: [],
        },
        coursesArr: {
            type: Array,
            default: [], // 已选择课程的id
        },
        memberArr: {
            type: Array,
            default: [], // 已选择会员的id
        },
        selectedRowsCourseArr:{
            type: Array,
            default: [], // 已选择会员的内容
        },
        selectedRowsMemberArr:{
            type: Array,
            default: [], // 已选择会员的内容
        }
    },
    data() {
        return {
            searchForm: {
                putawayFlag: 2,
                name: "",
            },
            putawayFlagArr: [{key: "", value: "全部状态"}],
            businessType: "1",
            selectedRows: "",
            courseCount: 1,
            coursePage: 1,
            coursePageSize: 20,
            courseList: [],
            courseLoading: false,
            courseSelectedRowKeys: [],
            courseColumns: [
                {
                    title: "课程名称",
                    dataIndex: "name",
                    key: "name",
                    scopedSlots: {customRender: "nameImgUrl"},
                },
                {
                    title: "状态",
                    dataIndex: "putawayFlag",
                    key: "putawayFlag",
                    width: "15%",
                    scopedSlots: {customRender: "putawayFlag"},
                },
                {
                    title: "课节数",
                    dataIndex: "lessonsCount",
                    key: "lessonsCount",
                    width: "15%",
                },
                {
                    title: "课程类型",
                    dataIndex: "courseType",
                    key: "courseType",
                    width: "15%",
                    scopedSlots: {customRender: "courseType"},
                },
                {
                    title: "定价",
                    dataIndex: "price",
                    key: "price",
                    width: "15%",
                    scopedSlots: {customRender: "price"},
                },
            ],
            statusArr: [{key: "", value: "全部状态"}],
            memberStatus: 1,
            memberCount: 1,
            memberPage: 1,
            memberPageSize: 20,
            memberList: [],
            memberLoading: false,
            memberSelectedRowKeys: [],
            memberColumns: [
                {
                    title: "会员名称",
                    dataIndex: "name",
                    key: "name",
                    scopedSlots: {customRender: "nameImgUrl"},
                },
                {
                    title: "状态",
                    dataIndex: "forbiddenStatus",
                    key: "forbiddenStatus",
                    width: "15%",
                    scopedSlots: {customRender: "forbiddenStatus"},
                },
                {
                    title: "会员价格",
                    dataIndex: "price",
                    key: "price",
                    width: "15%",
                },
                {
                    title: "有效期",
                    dataIndex: "days",
                    key: "days",
                    width: "15%",
                },
            ],
            selectedRowsCourse:[],
            selectedRowsMember:[],
        };
    },
    created() {
        if (this.modalVisible) {
            this.businessType =
                typeof this.tabType == String ? this.tabType : this.tabType.toString();
            let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
            dictionaryObj.putawayFlag.forEach((item) =>
                this.putawayFlagArr.push(item)
            );
            dictionaryObj.disableOrEnabled.forEach((item) =>
                this.statusArr.push(item)
            );
            
            this.switchHandle();
            this.$nextTick(() => {
                let selectWrap = "";
                selectWrap = document.querySelector(".memberTable .ant-table-body");
                this.memberSelectedRowKeys = this.memberArr;
                this.selectedRowsMember = this.selectedRowsMemberArr
                selectWrap = document.querySelector(".courseTable .ant-table-body");
                this.courseSelectedRowKeys = this.coursesArr;
                this.selectedRowsCourse = this.selectedRowsCourseArr
                var lastScrollTop = 0;
                selectWrap.addEventListener("scroll", () => {
                    const scrollDistance =
                        selectWrap.scrollHeight -
                        selectWrap.scrollTop -
                        selectWrap.clientHeight;
                    if (
                        scrollDistance <= 200 &&
                        this.courseCount > this.courseList.length
                    ) {
                        if (this.businessType == 2 && this.isShowMember) {
                            ++this.memberPage;
                            let countAll = Math.ceil(this.memberCount / this.memberPageSize);
                            if (countAll < this.memberPage) return;
                        } else {
                            ++this.coursePage;
                            let countAll = Math.ceil(this.courseCount / this.coursePageSize);
                            if (countAll < this.coursePage) return;
                        }
                        this.switchHandle();
                    }
                });
            });
        }
    },
    methods: {
        // 根据类型type 判断 请求不同接口
        switchHandle() {
            switch (this.type) {
                case 0:
                    if (this.businessType == 1) {
                        this.CourseListApi();
                    } else {
                        this.MemberListApi();
                    }
                    break;
                case 1:
                    this.TabExistsCourseInfoApi();
                    break;
                default:
                    break;
            }
        },
        // 0 - 课程管理列表
        CourseListApi() {
            this.courseLoading = true;
            GetCourseList({
                courseType: 0,
                name: this.searchForm.name,
                pageNo: this.coursePage,
                pageSize: this.coursePageSize,
                putawayFlag: this.searchForm.putawayFlag,
            }).then((res) => {
                if (res.code == 200) {
                    this.courseLoading = false;
                    this.courseCount = res.data.count;
                    let newList = res.data.data;
                    newList.map((item) => {
                        item.key = item.courseNo;
                    });
                    this.courseList = this.courseList.concat(newList);
                }
            });
        },
        // 0 - 会员列表数据
        MemberListApi() {
            this.memberLoading = true;
            MemberListApi({
                keyword: this.searchForm.name,
                status: this.memberStatus,
                pageNo: this.memberPage,
                pageSize: this.memberPageSize,
            }).then((res) => {
                if (res.code == 200) {
                    this.memberLoading = false;
                    this.memberCount = res.data.count;
                    let newList = res.data.data;
                    newList.map((item) => {
                        item.key = item.memberNo;
                    });
                    this.memberList = this.memberList.concat(newList);
                }
            });
        },
        // tab 去重获取课程列表数据
        TabExistsCourseInfoApi() {
            TabExistsCourseInfoApi({
                bizType: 1,
                pageNo: this.coursePage,
                pageSize: this.coursePageSize,
                putawayFlag: this.searchForm.putawayFlag,
                name: this.searchForm.name,
            }).then(({code, data}) => {
                if (code == 200) {
                    this.courseLoading = false;
                    let newData = data.data.map((item) => {
                        return JSON.parse(JSON.stringify(item).replace(/courseNo/g, "key"));
                    });
                    this.courseList = this.courseList.concat(newData);
                    this.courseCount = data.count;
                }
            });
        },
        // tab点击事件
        tabChange(key) {
            this.businessType = key;
            if (key == 2) {
                this.coursePage = 1;
                this.searchForm.name = "";
                this.memberList = [];
            } else {
                this.memberPage = 1;
                this.searchForm.name = "";
                this.courseList = [];
            }
            this.switchHandle();
        },
        // 搜索 - 课程切换状态
        changePutawayFlag(val) {
            this.searchForm.putawayFlag = val;
            this.courseList = [];
            this.coursePage = 1;
            this.switchHandle();
        },
        // 搜索 - 会员切换状态
        changeStatus(val) {
            this.memberStatus = val;
            this.memberList = [];
            this.memberPage = 1;
            this.switchHandle();
        },
        // 搜索事件
        handleSubmit() {
            if (this.businessType == 1) {
                this.courseList = [];
                this.coursePage = 1;
            } else if (this.businessType == 2) {
                this.memberList = [];
                this.memberPage = 1;
            }
            this.switchHandle();
        },
        // 课程管理选择事件
        onSelectCourseChange(selectedRowKeys, selectedRows) {
            this.courseSelectedRowKeys = selectedRowKeys;
            this.selectedRowsCourse = selectedRows;
        },
        // 会员管理选择事件
        onSelectMemberChange(selectedRowKeys, selectedRows) {
            this.memberSelectedRowKeys = selectedRowKeys;
            this.selectedRowsMember = selectedRows;
        },
        ok() {
            this.$emit("ok", this.courseSelectedRowKeys, this.memberSelectedRowKeys, this.selectedRowsCourse, this.selectedRowsMember);
        },
    },
};
</script>

<style lang="less" scoped>
.search-box {
    margin-bottom: 16px;
}

.course-img {
    width: 50px;
    border-radius: 4px;
    margin-right: 16px;
}

// 改变滚动条样式
/deep/ .ant-table-body::-webkit-scrollbar {
    width: 4px;
    height: 10px;
    background-color: rgba(0, 0, 0, 0);
}

/deep/ .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}

/deep/ .ant-table-body::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
}
</style>
